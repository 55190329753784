import * as React from 'react'

const Page = () => {
    return (
        <>
            <h2>Transkription zu:<br/>Die Beschlüsse des bayerischen Reformlandtags 1848, 4. Juni 1848</h2>
            <p>
                Gesetz, die Wahl der Landtags-Abgeordneten betreffend (Beilage IV zum Landtagsabschied vom 4. Juni 1848):
                <br/>Maximilian II.<br/>von Gottes Gnaden König von Bayern,<br/>Pfalzgraf bei Rhein,<br/>
                Herzog von Bayern, Franken und Schwaben ect. ect.
            </p>
            <p>
                Wir haben nach Vernehmung Unseres Staatsrathes und mit Beirath und Zustimmung der Lieben und Getreuen,
                der Stände des Reichs, unter Beobachtung der im § 7 Tit. X. der Verfassungs-Urkunde vorgeschriebenen
                Formen, beschlossen und verordnen, was folgt:
            </p>
            <p>
                Artikel 1. Die Wahl der Landtags-Abgeordneten geschieht im Verhältnisse von je Einem Abgeordneten auf
                31,500 Seelen der Gesammt-Bevölkerung des Königreichs.
            </p>
            <p>
                Artikel 2. Die hiernach sich ergebende Zahl von Abgeordneten wird nach obigem Verhältnisse durch die
                Regierung auf die einzelnen Kreise vertheilt.
            </p>
            <p>
                Artikel 3. Die Wahl findet in zwei gesonderten Handlungen statt:
                a) mittelst Wahl der Wahlmänner (Urwahl),
                b) mittelst Wahl der Abgeordneten durch die Wahlmänner.
            </p>
            <p>
                Artikel 4. Zu jeder gültigen Wahl ist die persönliche Anwesenheit des Wählenden erforderlich.
                Stellvertretung findet nicht Statt.
                Nur derjenige wird zur Wahl zugelassen, welcher erweislich den Verfassungseid abgeleistet hat.
                - Die Wahlmänner haben außerdem bei der Wahlhandlung den im
            </p>
        </>
    )
}

export default Page;
