import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "../muenchen-prannerstrasse/facsimile";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Für das ganze Königreich<br/><span>&nbsp;</span>besteht eine allgemeine<br/>in zwey
                        Kammern<br/><span>&nbsp;</span>abgetheilte Stände-Versammlung.“</h2>
                    <div className="inner-container">
                        <Container.If env="t">
                            <p> Das Gebäude an der Prannerstraße beherbergte von 1819 bis 1933 das bayerische Parlament.
                                Die Verfassung des Königreichs Bayern von 1818 sah die Mitwirkung einer
                                Ständeversammlung an der Gesetzgebung vor. Diese bestand aus der Kammer der Reichsräte
                                mit Vertretern aus Adel und Geistlichkeit und der Kammer der vom Volk gewählten
                                Abgeordneten. Wahlberechtigt war in Abhängigkeit von Steuerzahlungen ein kleinerer Teil
                                der Bevölkerung.
                            </p>
                            <p>
                                Im Zuge der europaweiten Revolution 1848 wurden weitreichende Verfassungsreformen
                                durchgeführt. Alle volljährigen männlichen Staatsbürger, die Steuern zahlten, konnten
                                jetzt die Mitglieder der Kammer der Abgeordneten wählen. Fortan hieß die
                                „Ständeversammlung“ offiziell „Landtag“. Wahlrechtsreformen 1905/06 erweiterten die
                                demokratische Basis des Landtags. Vergeblich beantragten 1917 Abgeordnete die
                                Parlamentarisierung des politischen Systems.
                            </p>

                            <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                                <p>
                                    Der entscheidende Wandel erfolgte 1918/19. In der Krise am Ende des Ersten
                                    Weltkriegs beschloss die zweite Kammer des Landtags die Demokratisierung Bayerns in
                                    Form einer parlamentarischen Monarchie. Noch vor der Umsetzung stürzte am 7.
                                    November 1918 die Revolution Regierung, Landtag und Monarchie. Kurt Eisner rief den
                                    Freistaat aus. Ziele der Revolutionsregierung waren das Ende des Kriegs und die
                                    Demokratisierung. Aus ersten allgemeinen Wahlen ging im Januar 1919 ein neuer
                                    Landtag hervor. Nach der Ermordung Eisners wich das Parlament nach Bamberg aus. Dort
                                    beschloss es im August 1919 eine demokratische Verfassung.
                                </p>
                                <p>In den folgenden 15 Jahren tagte der in seinen Kompetenzen wesentlich gestärkte
                                    Landtag in der Münchner Prannerstraße. Unter dem Druck des NS-Regimes gab die
                                    Mehrheit im Landtag 1933 seine Kompetenzen preis. Mit dem „Gesetz über den Neuaufbau
                                    des Reiches“ wurde der Landtag aufgelöst. Im Krieg wurde das Gebäude zerstört. Der
                                    im Dezember 1946 erstmals wieder frei gewählte Bayerische Landtag etablierte sich ab
                                    1949 im Maximilianeum in München.
                                </p>
                            </Container.More>
                        </Container.If>
                        <Container.If env="w">
                            <p> Das Gebäude an der Prannerstraße beherbergte von 1819 bis 1933 das bayerische Parlament.
                                Die Verfassung des Königreichs Bayern von 1818 sah die Mitwirkung einer
                                Ständeversammlung an der Gesetzgebung vor. Diese bestand aus zwei Kammern: der ersten,
                                der Kammer der Reichsräte mit Vertretern des Adels und der Geistlichkeit, und der
                                zweiten, der Kammer der vom Volk gewählten Abgeordneten. Wahlberechtigt war in
                                Abhängigkeit von Steuerzahlungen ein kleinerer Teil der Bevölkerung. Die Rechte der
                                Ständeversammlung trugen zu einer Dynamisierung des frühen Parlamentarismus bei.
                            </p>
                            <p>
                                Im Zuge der europaweiten Revolution 1848 wurden weitreichende Verfassungsreformen
                                durchgeführt. Alle volljährigen männlichen Staatsbürger, die Steuern zahlten, konnten
                                jetzt die Mitglieder der Kammer der Abgeordneten wählen. Fortan hieß die
                                „Ständeversammlung“ offiziell „Landtag“. Wahlrechtsreformen 1905/06 erweiterten die
                                demokratische Basis des Landtags. Vergeblich beantragten 1917 die MSPD-Abgeordneten
                                Erhard Auer und Max Süßheim die Parlamentarisierung des politischen Systems.
                            </p>

                            <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                                <p>
                                    Der entscheidende Wandel erfolgte 1918/19. In der Krise am Ende des Ersten
                                    Weltkriegs beschloss die zweite Kammer des Landtags die Demokratisierung Bayerns in
                                    Form einer parlamentarischen Monarchie. Noch vor der Umsetzung stürzte die
                                    Revolution am 7. November 1918 Regierung, Landtag und Monarchie. Kurt Eisner rief
                                    den Freistaat (Republik) aus. Ziele der Revolutionsregierung waren das Ende des
                                    Kriegs und die Demokratisierung. Aus ersten allgemeinen Wahlen ging im Januar 1919
                                    ein neuer Landtag hervor. Nach der Ermordung Eisners und einer Schießerei im Landtag
                                    sowie der Radikalisierung der Revolution in München wich das Parlament nach Bamberg
                                    aus. Dort beschloss es im August 1919 eine demokratische Verfassung.
                                </p>
                                <p>In den folgenden fünfzehn Jahren tagte der in seinen Kompetenzen wesentlich gestärkte
                                    Landtag wieder in der Münchner Prannerstraße, wo sich angesichts der
                                    Modernisierungserfordernisse im Land sowie der Krisen der Weimarer Republik ein
                                    reges parlamentarisches Leben entfaltete. Unter dem Druck des NS-Regimes gab die
                                    Mehrheit im Landtag 1933 seine Kompetenzen preis. Mit dem „Gesetz über den Neuaufbau
                                    des Reiches“ wurde der bayerische Landtag aufgelöst. Im Krieg wurde das Gebäude
                                    zerstört. Mit der Erneuerung von Staatlichkeit und Demokratie in Bayern etablierte
                                    sich der im Dezember 1946 erstmals wieder frei gewählte Landtag ab 1949 im
                                    Maximilianeum in München.
                                </p>
                            </Container.More>
                        </Container.If>
                        <br/>
                        {/*Facsimile*/}
                        <br/>
                        <div className="center">
                            <Container.Facsimile
                                pages={Pages['04_05']}
                                img="/images/Muenchen-Prannestr/prannerstr_bild_0012.jpg"
                                alttext="Die Beschlüsse des bayerischen Reformlandtags 1848, 4. Juni 1848">
                                <figcaption>
                                    Die Beschlüsse des bayerischen Reformlandtags 1848, 4. Juni 1848
                                    <span className="description">
                                        Im Gesetz zur Wahl der Abgeordneten war festgelegt, dass das aktive und passive
                                        Männerwahlrecht nur noch an die Entrichtung einer direkten Steuer unbestimmter
                                        Höhe gebunden war. Das Prinzip der Wahl nach Ständen wurde abgeschafft.
                                    </span>
                                    <span
                                        className="copy">Abb. Bayerisches Hauptstaatsarchiv, Bayerischer Landtag 10229</span>
                                </figcaption>
                            </Container.Facsimile>
                        </div>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
