import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
               <div className="col-12">
                   <div className="center">
                   <figure className="lightbox karte">
                       <a className="glightbox" data-gallery="gallery2"
                          href={"/images/Muenchen-Prannestr/Zentren-Revolution-1848.png"}
                          data-glightbox="title: Zentren der Revolution 1848/49 in Europa">
                           <StaticImage src="../../../../static/images/Muenchen-Prannestr/Zentren-Revolution-1848.png" width={335} layout="fixed" placeholder="none"  alt="Zentren der Revolution 1848/49 in Europa"/>
                       </a>
                       <figcaption>Zentren der Revolution 1848/49 in Europa</figcaption>
                   </figure>
                   </div>
               </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock4White
