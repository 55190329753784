import * as F04_08P1 from "./04_08/Page1";

import * as F04_05P1 from "./04_05/Page1";
import * as F04_05P2 from "./04_05/Page2";
//import * as F04_05P3 from "./04_05/Page3";
//import * as F04_05P4 from "./04_05/Page4";
//import * as F04_05P5 from "./04_05/Page5";
//import * as F04_05P6 from "./04_05/Page6";
//import * as F04_05P7 from "./04_05/Page7";

const Pages = {
    '04_08': {
        1: ['/facsimile/prannerstrasse/04_08_Proklamation/04_08_Proklamation_1848_gr_Z1883.jpg', F04_08P1],
    },
    '04_05': {
        1: ['/facsimile/prannerstrasse/04_05_Beschluesse/Landtag_10229_0023.jpg', F04_05P1],
        2: ['/facsimile/prannerstrasse/04_05_Beschluesse/Landtag_10229_0024.jpg', F04_05P2],
        //3: ['/facsimile/prannerstrasse/04_05_Beschluesse/Landtag_10229_0025.jpg', F04_05P3],
        //4: ['/facsimile/prannerstrasse/04_05_Beschluesse/Landtag_10229_0026.jpg', F04_05P4],
        //5: ['/facsimile/prannerstrasse/04_05_Beschluesse/Landtag_10229_0027.jpg', F04_05P5],
        //6: ['/facsimile/prannerstrasse/04_05_Beschluesse/Landtag_10229_0028.jpg', F04_05P6],
        //7: ['/facsimile/prannerstrasse/04_05_Beschluesse/Landtag_10229_0029.jpg', F04_05P7],
    },
};

export {Pages};
