import * as React from 'react'

const Page = () => {
    return (
        <div>
            <h2>Transkription zu:<br/>Erinnerungsblatt zur königlichen Proklamation vom 6. März 1848</h2>
            <p>
                Königliche Proklamation.<br/>
                Ich habe mich entschlossen die Stände meines Reiches um Mich zu versammeln; dieselben sind auf den 16. d. Mts. in die Hauptstadt berufen.
                Die Wünsche Meines Volkes haben in Meinem Herzen jederzeit vollen Wiederhall gefunden. An die Stände des Reiches werden ungesäumt Gesetz-Vorlagen gelangen, unter anderen:
                über die verfassungsmässige Verantwortlichkeit der Minister;
                über vollständige Preßfreiheit;
                über Verbesserung der Stände-Wahl-Ordnung;
                über Einführung der Oeffentlichkeit und Mündlichkeit in die Rechtspflege mit Schwur-Gerichten;
                über die in der IX. Verfassungs-Beilage angedeutete umfassendere Fürsorge für die Staatsdiener und deren Relikten; dann deren Ausdehnung auf die übrigen Angestellten des Staates;
                über Verbesserung der Verhältnisse der Israeliten.
                Ferner ordne Ich in diesem Augenblicke die schleunige Abfassung eines Polizei-Gesetz-Buches an; ebenso befehle Ich die unverzügliche Beeidigung Meines Heeres auf die Verfassung, und lasse Ich von heute an die Censur über äussere wie innere Angelegenheiten ausser Anwendung treten.
                Bayern erkennt in diesem Entschlusse die angestammte Gesinnung der Wittelsbacher.
                Ein großer Augenblick ist in der Entwicklung der Staaten eingetreten. Ernst ist die Lage Teutschlands. Wie Ich für [die] teutsche Sache denke und fühle, davon zeugt Mein ganzes Leben. Teutschlands Einheit durch wirksame Maaßnahmen zu stärken, dem Mittelpunkte des vereinten Vaterlandes neue Kraft und nationale Bedeutsamkeit mit einer Vertretung der teutschen Nation am Bunde zu sichern, und zu dem Ende die schleunige Revision der Bundes-Verfassung in Gemäßheit der gerechten Erwartungen Teutschlands herbeizuführen, wird mir ein theuerer Gedanke, wird Ziel meines Strebens bleiben.
                Bayerns König ist stolz darauf, ein teutscher Mann zu seyn.
                Bayern! Eurer Vertrauen wird erwiedert, es wird gerechtfertiget werden! Schaaret Euch um den Thron. Mit Euerem Herrscher vereint, vertreten durch Euere verfassungsmässigen Organe, laßt Uns erwägen, was Uns, was dem gemeinsamen Vaterlande Noth thut.
                Alles für Mein Volk! Alles für Teutschland!
                München, den 6. März 1848.
                Ludwig.
                Maximilian, Kronprinz. Luitpold, Prinz von Bayern. Adalbert, Prinz von Bayern. Karl, Prinz von Bayern.
                Fürst v. Oettingen-Wallerstein. v. Beisler. v. Heres. v. der Mark. v. Voltz.“
            </p>
            <p>
                „Zur Erinnerung an den 7. [!] März 1848. Den biedern Bürgern u. Unterzeichnern der Adreße an
                Sr. Mayest. den König hochachtungsvoll gewidmet“, 1848
            </p>
        </div>
    )
}

export default Page;
