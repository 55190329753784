import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "../muenchen-prannerstrasse/facsimile";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock3Parallax = (key, ortName) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="bg-img parallax-bg parallax-prannestrasse" key={key}>
                <Container.Row>
                    <div className="col-12">
                        <h2>Die Märzrevolution 1848 und der bayerische Landtag</h2>
                    </div>
                </Container.Row>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Muenchen-Prannestr/prannerstr_bild_0009.jpg"}
                               data-glightbox="title: Münchner Bürger stürmen das Zeughaus am Anger, März 1848; description: .custom-desc1_6; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Muenchen-Prannestr/prannerstr_bild_0009.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Münchner Bürger stürmen das Zeughaus am Anger, März 1848"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_6">
                                <p>
                                    Am 3. März unterschrieben in München Tausende Bürger die überall im Deutschen Bund
                                    gestellten „Märzforderungen“. Sie forderten Reformen, wie die
                                    Ministerverantwortlichkeit
                                    gegenüber dem Parlament, Pressefreiheit und ein liberales Wahlgesetz. Tags darauf
                                    stürmten die Bürger das Zeughaus und bemächtigten sich der dort gelagerten Waffen.
                                    Das
                                    revolutionäre Geschehen in München beruhigte sich jedoch schnell wieder.
                                </p>
                                <p className="copy">
                                    Abb. Stadtarchiv München, DE-1992-HV-BS-C-07-08
                                </p>
                            </div>
                            <figcaption>
                                Münchner Bürger stürmen das Zeughaus am Anger, März 1848
                                <span className="description">
                                Am 3. März unterschrieben in München Tausende Bürger die überall im Deutschen Bund
                                gestellten „Märzforderungen“. Sie forderten Reformen, wie die Ministerverantwortlichkeit
                                gegenüber dem Parlament, Pressefreiheit und ein liberales Wahlgesetz. Tags darauf
                                stürmten die Bürger das Zeughaus und bemächtigten sich der dort gelagerten Waffen. Das
                                revolutionäre Geschehen in München beruhigte sich jedoch schnell wieder.
                            </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Muenchen-Prannestr/prannerstr_bild_0011.jpg"}
                               data-glightbox="title: Kammer der Abgeordneten des bayerischen Landtags von 1866 bis 1868, Fotomontage von Atelieraufnahmen der 141 Abgeordneten; description: .custom-desc1_9; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Muenchen-Prannestr/prannerstr_bild_0011.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Kammer der Abgeordneten des bayerischen Landtags von 1866 bis 1868, Fotomontage von Atelieraufnahmen der 141 Abgeordneten"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_9">
                                <p>
                                    Der Landtag von 1849 war der erste, der nach dem neuen Wahlrecht gewählt wurde.
                                    Damit
                                    wuchs auch das politische Bewusstsein in der Bevölkerung. Vor der Wahl entwickelten
                                    sich
                                    politische Vereinigungen, wie Liberale und Konservative, Regierungstreue und
                                    Oppositionelle. In den 1860er-Jahren gründeten sich erste Parteien.
                                </p>
                                <p className="copy">
                                    Abb. Stadtarchiv München, DE-1992-FS-AB-ERG-0288
                                </p>
                            </div>
                            <figcaption>
                                Kammer der Abgeordneten des bayerischen Landtags von 1866 bis 1868, Fotomontage von
                                Atelieraufnahmen der 141 Abgeordneten
                                <span className="description">
                                Der Landtag von 1849 war der erste, der nach dem neuen Wahlrecht gewählt wurde. Damit
                                wuchs auch das politische Bewusstsein in der Bevölkerung. Vor der Wahl entwickelten sich
                                politische Vereinigungen, wie Liberale und Konservative, Regierungstreue und
                                Oppositionelle. In den 1860er-Jahren gründeten sich erste Parteien.
                            </span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Muenchen-Prannestr/prannerstr_bild_0008.jpg"}
                               data-glightbox="title: Werk der Maschinenbau-Actiengesellschaft Nürnberg, eine der ersten großen Fabriken, 1895 ; description: .custom-desc1_7; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Muenchen-Prannestr/prannerstr_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Werk der Maschinenbau-Actiengesellschaft Nürnberg, eine der ersten großen Fabriken, 1895"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_7">
                                <p>
                                    Unzufriedenheit war in der Bevölkerung weit verbreitet, nicht nur über die
                                    politische
                                    Rechtlosigkeit, sondern auch über wachsende soziale Probleme unter anderem als Folge
                                    des
                                    Bevölkerungswachstums und der Anfänge der Industrialisierung.
                                </p>
                                <p className="copy">
                                    Abb. Historisches Archiv MAN Augsburg
                                </p>
                            </div>
                            <figcaption>
                                Werk der Maschinenbau-Actiengesellschaft Nürnberg, eine der ersten großen Fabriken, 1895
                                <span className="description">
                                Unzufriedenheit war in der Bevölkerung weit verbreitet, nicht nur über die politische
                                Rechtlosigkeit, sondern auch über wachsende soziale Probleme unter anderem als Folge des
                                Bevölkerungswachstums und der Anfänge der Industrialisierung.
                            </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <Container.Facsimile
                                pages={Pages['04_08']}
                                img="/images/Muenchen-Prannestr/prannerstr_bild_0010.jpg"
                                alttext="Erinnerungsblatt zur königlichen Proklamation vom 6. März 1848">
                                <figcaption>
                                    Erinnerungsblatt zur königlichen Proklamation vom 6. März 1848
                                    <span className="description">
                                König Ludwig I. reagierte mit der Märzproklamation, mit der er die Einberufung der
                                Ständeversammlung und seine Reformbereitschaft bekannt gab. Nachdem er am 19. März
                                freiwillig abgedankt hatte, eröffnete der neue König, Maximilian II., am 22. März den
                                Reformlandtag von 1848.
                            </span>
                                    <span className="copy">Abb. Münchner Stadtmuseum, Graphiksammlung, Z 1883</span>
                                </figcaption>
                            </Container.Facsimile>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlock3Parallax
