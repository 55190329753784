import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                Art. 17 vorgeschriebenen Wählereid zu schwören.
                Der Eid nach Tit. VII § 25 und Tit. X. § 3 der Verfassungs-Urkunde kann bei Angehörigen nicht
                christlicher Confessionen mit Hinweglassung des Beisatzes: "und sein heiliges Evangelium" geleistet werden.

            </p>
            <p>
                Artikel 5. Aktiv wahlfähig ist jeder Staatsbürger (§ 3 Tit. IV. der Verf.-Urk.) und jeder volljährige
                Staats-Angehörige, welcher dem Staate eine direkte Steuer entrichtet, insofern er nicht wegen Verbrechen,
                oder des Vergehens der Fälschung, des Betrugs, des Diebstahls oder der Unterschlagung verurtheilt worden ist.
            </p>
            <p>
                Artikel 6. Als Wahlmann kann jeder bayerische Staatsbürger (§ 3 Tit. IV. der Verf.Urk.) gewählt werden,
                soferne er das 25. Lebensjahr zurückgelegt und die übrigen Eigenschaften des Art. 5 für sich hat.
            </p>
            <p>
                Artikel 7. Als Abgeordneter ist Jeder wählbar, welcher das 30. Lebensjahr zurückgelegt hat, und die
                übrigen im Art. 5 angegebenen Eigenschaften besitzt.
            </p>
            <p>
                Artikel 8. Weder die aktive noch die passive Wahlfähigkeit ist an ein bestimmtes Glaubensbekenntniß gebunden.
            </p>
            <p>
                Artikel 9. Für jede der beiden Wahlhandlungen werden besondere Wahlbezirke gebildet.
            </p>
            <p>
                Artikel 10. In der ersten Wahlhandlung wird auf je 500 Seelen ein Wahlmann gewählt. Sämmtliche Wahlmänner
                eines Bezirkes wählen die gemäß Art, 12 und Art. 13 zu bestimmende Zahl der Abgeordneten und einen
                Ersatzmann für jeden derselben in gesonderten Wahlhandlungen.
            </p>
        </>
    )
}

export default Page;
