import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key) => {
    return (
        <Container.HeaderPart background="Prannestraße" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>1818 – 1933<br/>
                                München: <br/>
                                Prannerstraße 8
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Verfassung</li>
                                <li>Parlamentarismus</li>
                                <li>Wahlen</li>
                            </ul>
                            <p>
                                Das Landtagsgebäude in der Prannerstraße war Sitz der 1818 geschaffenen
                                Ständeversammlung, ab 1848 Landtag. Hier entfaltete sich der Frühparlamentarismus und
                                die Dynamik zum Wandel Bayerns zur parlamentarischen Demokratie sowie demokratische
                                Politik in den Jahren der Weimarer Republik bis 1933.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Muenchen-Prannestr/prannerstr_bild_0006.jpg"}
                                   data-glightbox="title: Eröffnung der ersten Ständeversammlung durch König Max I. Joseph am 4. Februar 1819 im Ständehaus; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Muenchen-Prannestr/prannerstr_bild_0006.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Eröffnung der ersten Ständeversammlung durch König Max I. Joseph am 4. Februar 1819 im Ständehaus"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p className="copy">
                                        Lithografie von Lorenzo Quaglio <br/>
                                        Abb. Stadtarchiv München, DE-1992-HV-BS-C-02-24
                                    </p>
                                </div>
                                <figcaption>
                                    Eröffnung der ersten Ständeversammlung durch König Max I. Joseph am 4. Februar 1819
                                    im Ständehaus
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Muenchen-Prannestr/prannerstr_bild_0004.jpg"}
                                   data-glightbox="title: Landtagsgebäude an der Prannerstraße nach dem Umbau von 1884; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Muenchen-Prannestr/prannerstr_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Landtagsgebäude an der Prannerstraße nach dem Umbau von 1884"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        Das Gebäude war 1818/19 von dem Architekten Leo von Klenze zum Ständehaus, dem
                                        späteren Landtag, umgestaltet worden. Im zur Prannerstraße gelegenen Gebäudeteil
                                        tagte die Kammer der Reichsräte, während die Kammer der Abgeordneten im hinteren
                                        Gebäudeteil untergebracht war. <br/>
                                    </p>
                                    <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                                </div>
                                <figcaption>
                                    Landtagsgebäude an der Prannerstraße nach dem Umbau von 1884
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
