import * as React from 'react'
import * as Container from "../../container/container";

const Video = (key) => {
    return (
        <Container.Part background="beige" id="trailer" key={key}>
            <Container.Row>
                <div className="col-12">
                    <div className="inner-container">
                        <h2>Auf den Spuren des Bayerischen Landtags mit Oberbürgermeister Dieter
                            Reiter.</h2>
                        <figure className="video">
                            <div className="yt-container yt-enabled">
                                <Container.Video
                                    url={"/videos/Filmmaterial/004_Prannerstr/210708_Prannerstraße mit Intro.mp4"}
                                    poster={"/images/Videowallpaper/prannerstr.jpg"}/>
                            </div>
                        </figure>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default Video
