import Headerimage from "./Headerimage";
import TextBlock1Blue from "./TextBlock1Blue";
import Novel from "./Novel";
import TextBlock2White from "./TextBlock2White";
import TextBlock3Parallax from "./TextBlock3Parallax";
import TextBlock4White from "./TextBlock4White";
import Video from "./Video";

const Parts = [
    Headerimage,
    TextBlock1Blue,
    Novel,
    TextBlock2White,
    TextBlock3Parallax,
    TextBlock4White,
    Video,
];

export {Parts};
