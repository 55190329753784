import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <Container.Fullwidth key={key.toString() + 'slider'}>
            <Container.Novel id={key} sliderTitleId="nodelTitle">
                <div className="bg-grey">
                    <p>1818–1934 München – Prannerstraße 8</p>
                    <h2>Im Mai nach der Revolution von 1848 – hitzige Landtagsdebatte über das Wahlrecht …</h2>
                    <StaticImage src="../../../../static/images/Muenchen-Prannestr/Novel/Szene_1_Bild_1.png" placeholder="none"  alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Muenchen-Prannestr/Novel/Szene_1_Bild_2.png" placeholder="none"  alt=""/>
                <StaticImage src="../../../../static/images/Muenchen-Prannestr/Novel/Szene_1_Bild_3.png" placeholder="none"  alt=""/>
                <StaticImage src="../../../../static/images/Muenchen-Prannestr/Novel/Szene_1_Bild_4.png" placeholder="none"  alt=""/>
                <StaticImage src="../../../../static/images/Muenchen-Prannestr/Novel/Szene_1_Bild_5.png" placeholder="none"  alt=""/>
                <div className="bg-grey">
                    <h2>Das Frauenwahlrecht war für die Abgeordneten 1848 noch in weiter Ferne …</h2>
                    <StaticImage src="../../../../static/images/Muenchen-Prannestr/Novel/Szene_2_Bild_1.png" placeholder="none" alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Muenchen-Prannestr/Novel/Szene_2_Bild_2.png" placeholder="none"  alt=""/>
            </Container.Novel>
        </Container.Fullwidth>
    )
}

export default Novel

