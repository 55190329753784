import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-Prannestr/prannerstr_bild_0005.jpg"}
                           data-glightbox="title: Ansicht des Plenarsaals der Abgeordnetenkammer, 1835; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-Prannestr/prannerstr_bild_0005.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Ansicht des Plenarsaals der Abgeordnetenkammer, 1835"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p>
                                Anfangs beklagten die Abgeordneten sich immer wieder über die ungünstigen
                                Platzverhältnisse im Saal. Beim Umbau 1884 wurden Sitze neu angeordnet und bekamen nun
                                auch Schreibpulte.
                            </p>
                            <p className="copy">
                                Abb. Bildarchiv Bayerischer Landtag
                            </p>
                        </div>
                        <figcaption>
                            Ansicht des Plenarsaals der Abgeordnetenkammer, 1835
                            <span className="description">
                                Anfangs beklagten die Abgeordneten sich immer wieder über die ungünstigen
                                Platzverhältnisse im Saal. Beim Umbau 1884 wurden Sitze neu angeordnet und bekamen nun
                                auch Schreibpulte.
                            </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-Prannestr/prannerstr_bild_0007.jpg"}
                           data-glightbox="title: Der Sitzungssaal der Kammer der Abgeordneten, 1906; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-Prannestr/prannerstr_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Der Sitzungssaal der Kammer der Abgeordneten, 1906"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Die Planzeichnung zeigt die Sitzverteilung für 156 Abgeordnete im Jahr 1906.
                            </p>
                            <p className="copy">
                                Federzeichnung von Friedrich Adelung <br/>
                                Abb. Bayerisches Hauptstaatsarchiv, Bayerischer Landtag 11749
                            </p>
                        </div>
                        <figcaption>
                            Der Sitzungssaal der Kammer der Abgeordneten, 1906
                            <span className="description">
                                 Die Planzeichnung zeigt die Sitzverteilung für 156 Abgeordnete im Jahr 1906.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
